var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',[_c('el-col',{attrs:{"span":24,"align":_vm.isNotSmallWindow ? 'middle' : '',"justify":_vm.isNotSmallWindow ? 'center' : ''}},[_c('el-container',[_c('el-main',[_c('el-card',{class:{'box-card': _vm.isNotSmallWindow}},[_c('h2',[_vm._v("注册")]),_c('el-form',{ref:"user",attrs:{"label-width":"15%","model":_vm.user,"rules":_vm.rules,"label-position":_vm.isNotSmallWindow ? '' : 'top'}},[_c('el-form-item',{attrs:{"label":"账号","rules":[
                  { required: true, message: '账号不能为空'},
                ],"prop":"uname"}},[_c('el-input',{attrs:{"maxlength":"8","minlength":"3","show-word-limit":"","clearable":"","autocomplete":"off"},on:{"blur":_vm.checkUname},model:{value:(_vm.user.uname),callback:function ($$v) {_vm.$set(_vm.user, "uname", $$v)},expression:"user.uname"}})],1),_c('el-form-item',{attrs:{"show-message":"","label":"密码","rules":[
                  { required: true, message: '密码不能为空'},
                ],"prop":"upwd"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","maxlength":"15","min":"6","clearable":"","show-password":""},on:{"blur":_vm.checkUpwd},model:{value:(_vm.user.upwd),callback:function ($$v) {_vm.$set(_vm.user, "upwd", $$v)},expression:"user.upwd"}})],1),_c('el-form-item',{attrs:{"show-message":"","label":"邮箱","rules":[
                  { required: true, message: '邮箱不能为空'},
                  { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],"prop":"email"}},[_c('el-input',{attrs:{"type":"email","autocomplete":"off","clearable":""},on:{"blur":_vm.checkEmail},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),(_vm.showVcode)?_c('el-form-item',{attrs:{"label":"验证码","rules":[
                  { required: true, message: '验证码不能为空'},
                ],"prop":"vcode"}},[_c('el-input',{attrs:{"type":"text","autocomplete":"off","clearable":""},model:{value:(_vm.user.vcode),callback:function ($$v) {_vm.$set(_vm.user, "vcode", $$v)},expression:"user.vcode"}})],1):_vm._e(),_c('el-form-item',{style:(_vm.isNotSmallWindow ? 'margin-left: 75%' : '')},[_c('el-button',{attrs:{"disabled":_vm.disableSendButton},on:{"click":_vm.sendVerificationCode}},[_vm._v(_vm._s(_vm.sendButtonText))])],1),_c('el-form-item',[_c('el-button',{class:{'sbtn':!_vm.isNotSmallWindow},staticStyle:{"width":"55%","margin-left":"-20%"},attrs:{"type":"primary","loading":_vm.registerLoading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("注册")])],1),_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toLogin()}}},[_vm._v("已经有账号，点击登录")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }