import Vue from 'vue'
import App from './App.vue'
import axios from './axios/axios';

// 引用UI框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入路由
import router from './router'
// 引入VueX
import store from './store/index';

// 引入MarkDown编辑器
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';

import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';

import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';

import createAlignPlugin from '@kangc/v-md-editor/lib/plugins/align';

import Prism from 'prismjs';


// 引入UUID
import { v4 as uuidv4 } from 'uuid';
// 引入CryptoJS
import CryptoJS from 'crypto-js';

Vue.prototype.$http = axios; // 将配置后的 Axios 实例挂载到 Vue 原型上，使得在组件中可以通过 this.$http 访问

Vue.prototype.$crypto = CryptoJS;

Vue.prototype.$uuid = uuidv4;

VueMarkdownEditor.use(createTodoListPlugin());
VMdPreview.use(createTodoListPlugin())

VueMarkdownEditor.use(createCopyCodePlugin());
VMdPreview.use(createCopyCodePlugin())

VueMarkdownEditor.use(createAlignPlugin());
VMdPreview.use(createAlignPlugin())


VMdPreview.use(vuepressTheme, {
  Prism,
});

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});



VueMarkdownEditor.use(createEmojiPlugin());
VMdPreview.use(createEmojiPlugin())

Vue.use(VMdPreview);

Vue.use(VueMarkdownEditor);

Vue.use(ElementUI);


Vue.config.productionTip = false


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
