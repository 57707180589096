<template>
  <div>
    <el-card shadow="never" style="border:none">
        <i class="el-icon-edit"></i>
        <!-- 通过woeCmd 判断显示哪个标题 -->
        <span v-if="woeCmd=='write'" class="comTitle">写日记</span>
        <span v-if="woeCmd=='edit'" class="comTitle">日记编辑</span>
        <hr>
        <el-form label-width="15%" :model="diaryData" ref="diaryData" :label-position="isNotSmallWindow ? '' : 'top'">

                <el-form-item
                  label="日记标题"
                  :rules="[
                    { required: true, message: '日记标题不能为空'},
                  ]"
                  prop="dtitle"
                >
                  <el-input v-model="diaryData.dtitle" maxlength="45" minlength="3" show-word-limit clearable autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="日记内容">
                <v-md-editor 
                v-model="diaryData.dcontent" 
                height='400px' 
                :disabled-menus="[]" 
                @upload-image="UploadImage" 
                left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji tip todo-list postionToolbar"
                :toolbar="toolbar"
                ></v-md-editor>
                </el-form-item>

                <el-form-item>
                  <el-button v-if="woeCmd=='write'" class="sbtn" type="primary" @click="sumbitTxt(diaryData)">提交</el-button>
                  <el-button v-if="woeCmd=='edit'" class="sbtn" type="primary" @click="updateTxt(diaryData)">保存</el-button>
                </el-form-item>
                
              </el-form>         
    </el-card>
    
  </div>
</template>

<script>



export default {
  name: 'writeDiary',
  data() {
    return {
    editdid:'',
    diaryData:{
      dtitle:'',
      dcontent:``
    },
    woeCmd:'write',
    toolbar:{
      postionToolbar: {
        title: '定位',
        icon: 'v-md-icon-tip',
        menus: [
          {
            name: 'menu1',
            text: '居左',
            action(editor) {
              editor.insert(function (selected) {
                const prefix = '::: align-left\n';
                const suffix = ':::';
                const placeholder = '请输入文本\n';
                const content = selected || placeholder;

                return {
                  text: `${prefix}${content}${suffix}`,
                  selected: content,
                };
              });
            },
          },
          {
            name: 'menu2',
            text: '居中',
            action(editor) {
              editor.insert(function (selected) {
                const prefix = '::: align-center\n';
                const suffix = ':::';
                const placeholder = '请输入文本\n';
                const content = selected || placeholder;

                return {
                  text: `${prefix}${content}${suffix}`,
                  selected: content,
                };
              });
            },
          },
          {
            name: 'menu3',
            text: '居右',
            action(editor) {
              editor.insert(function (selected) {
                const prefix = '::: align-right\n';
                const suffix = ':::';
                const placeholder = '请输入文本\n';
                const content = selected || placeholder;

                return {
                  text: `${prefix}${content}${suffix}`,
                  selected: content,
                };
              });
            },
          },
        ],
        
      }
    },
    windowWidth: window.innerWidth
       
    }
    
  },
  methods:{
    // 提交日记 --- 保存数据库
    sumbitTxt(diaryData){
      // 获取当前时间
      var ntime = new Date().toLocaleString();
      // 访问后端 did传入uuid 日记id为uuid
      this.$http.post('/diary/add', {
        did:this.$uuid(),
        dtitle:diaryData.dtitle,
        dcontent:diaryData.dcontent,
        dtime:ntime,
        uname:this.$store.getters.getUname
      })
    .then(response => {
      if(response.data=='1'){
        this.$message({
          message: '提交成功',
          type: 'success'
        });
        // 成功后清空当前数据
        this.diaryData={}
      }
            
    })
    .catch(error => {
      this.$message.error('网络好像出错了！别着急等会再试试')
      console.error(error);
    });
    },
    // 编辑器中的上传图片方法
    UploadImage(event, insertImage, files){
      const file = files[0];

      // 创建一个FormData对象，将文件添加到其中
      const formData = new FormData();
      formData.append('image', file);

      // 使用this.$http发送POST请求上传图片
      this.$http.post('/diary/upload-image', formData)
        .then((response) => {
          const imageUrl = response.data.imageUrl;

          // 在编辑框中插入图片
          insertImage({
            url: imageUrl,
            desc: '图片描述',
          });
        })
        .catch((error) => {
          console.error('图片上传失败:', error);
        });
    },
    // 日记更新方法
    updateTxt(diaryData){
      this.$http.post('/diary/update', {dtitle:diaryData.dtitle,dcontent:diaryData.dcontent,dtime:new Date().toLocaleString(),did: this.editdid})
            .then(response => {  
                if(response.data=='102'){
                  this.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  this.woeCmd='write'
                  this.$store.commit("setWoecmd",'write')
                  this.$emit('showFlag', 4)
                  this.diaryData=[]
                  
                }
                // location.reload()
                
            })
            .catch(error => {
                this.$message.error('网络好像出错了！别着急等会再试试')
                console.error(error);
            });
    },
    getwoeCmdAndDate(){
      // 组件加载时从VUEx中获取 woeCmd
      const woecmd=this.$store.state.woecmd
      if(woecmd!=''&&woecmd=="edit"){
        this.woeCmd="edit"
        if(this.$store.state.editId!=null){
              this.editdid=this.$store.state.editId
              this.$http.post('/diary/getPOneDiary', { did: this.editdid})
              .then(response => {
                  this.diaryData=response.data[0]
                  
              })
              .catch(error => {
                  this.$message.error('网络好像出错了！别着急等会再试试')
                  console.error(error);
              });
          }
      }
  },
  Resize() {
      this.windowWidth = window.innerWidth;
  },
  },
computed: {
    isNotSmallWindow() {
      return this.windowWidth > 768; // 设置阈值为768px，可以根据需要调整
    }
  },
  mounted(){
    // 组件加载时从VUEx中获取 woeCmd
    this.getwoeCmdAndDate()  
    window.addEventListener('resize', this.Resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.Resize);
  }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comTitle{
    font-size: 18px;
    font-weight: bold;
}
.sbtn{
  margin: 2% 0 0 0;
  width: 35%;
}
</style>
