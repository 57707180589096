<template>
  <div>
    <el-row :xs="12" :md="6">
      <el-col  :span="24" :align="isNotSmallWindow ? 'middle' : ''" :justify="isNotSmallWindow ? 'center' : ''" >
        <el-container>
          <el-main>
            <el-card :xs="4" :md="6" :class="{'box-card': isNotSmallWindow}">
              <h2>登录</h2>
              <el-form  label-width="15%" :model="user" ref="user" :label-position="topOrNot()">
                <el-form-item>
                  <el-switch
                  :class="{'switchBtn': !isNotSmallWindow}"
                  @change='swbtnchange'
                  style="margin-right: -60%;margin-bottom: 2%;"
                    v-model="loginw"
                    active-text="邮箱登录"
                    inactive-text="账号登录">
                    
                  </el-switch>
                </el-form-item>
                  
                <el-form-item
                  label="账号"
                  :rules="[
                    { required: true, message: '账号不能为空'},
                  ]"
                  prop="uname"
                >
                  <el-input v-model="user.uname" show-word-limit clearable autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item show-message
                  label="密码"
                  :rules="[
                    { required: true, message: '密码不能为空'},
                  ]"
                  prop="upwd"
                >
                <el-input type="password" v-model="user.upwd" autocomplete="off" maxlength="15" min="8" clearable show-password></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button :class="{'sbtn':!isNotSmallWindow}" style="width: 55%;margin-left: -20%;" type="primary" @click="submit(user)">登录</el-button>
                </el-form-item>
                <el-link type="primary" @click="toRegister()">还没有账号？点击注册</el-link>
                |
                <el-link type="primary" @click="toPr()">忘记密码？点击找回</el-link>
              </el-form>
            </el-card>
          </el-main>
        </el-container>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      user:{
        uname: '',
        upwd: '',
      },
      loginw:false,
      // 用于切换 提交表单方式
      loginNum:false,
      windowWidth: window.innerWidth

     
    }
  },
  methods:{
  submit(userData){
    this.$refs.user.validate(valid => {
        if (valid) {
          if(this.loginNum==false){
            this.$http.post('/users/login', { uname: userData.uname,upwd:userData.upwd})
            .then(response => {
            if(response.data=='1'){
              this.$message({
                message: '登录成功',
                type: 'success'
              });
              // 存储登录用户名 用于后端加密
              this.$store.commit('setUname',userData.uname)
              // 调用加密 并将token保存到本地
              this.$store.dispatch('fetchData')
              // 跳转到主页
              this.$router.push("/home")
            }else{
              this.$message.error('用户或密码错误，请重新输入')
              this.$refs.user.resetFields()
            }
            
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
          }else{
            this.$http.post('/users/loginByEmail', { email: userData.uname,upwd:userData.upwd})
            .then(response => {
              // console.log(response.data[0].uname)
            if(response.data[0].uname){
              this.$message({
                message: '登录成功',
                type: 'success'
              });
              // 存储登录用户名 用于后端加密
              this.$store.commit('setUname',response.data[0].uname)
              // 调用加密 并将token保存到本地
              this.$store.dispatch('fetchData')
              // 跳转到主页
              this.$router.push("/home")
            }else{
              this.$message.error('用户或密码错误，请重新输入')
              this.$refs.user.resetFields()
            }
            
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
          }
          
        }
      });
    
    // console.log(userData.uname)
    // console.log(userData.upwd)
  },
  toRegister(){
    this.$router.push('/register')
  },
  toPr(){
    this.$router.push('/passwordRecover')
  },
  swbtnchange(){
    this.loginNum=!this.loginNum
  },
  Resize() {
      this.windowWidth = window.innerWidth;
  },
  topOrNot(){
    if(this.isNotSmallWindow){
      return ''
    }else{
      return 'top'
    }
  }
},
computed: {
    isNotSmallWindow() {
      return this.windowWidth > 768; // 设置阈值为768px，可以根据需要调整
    }
  },
mounted() {
    window.addEventListener('resize', this.Resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.Resize);
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box-card{
  width: 40%;
}
.switchBtn{
  margin-right: -40% !important;
}
.sbtn{
  margin-left: 0 !important;
}
</style>
