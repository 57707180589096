import Vue from 'vue'
import VueRouter from 'vue-router'
import loginView from '../views/login.vue'
import Home from '../views/home.vue'
import registerView from '../views/register.vue'
import PasswordRecoverView from '../views/PasswordRecover.vue'
// import store from '../store/index.js'; // 引入 Vuex Store
import axios from 'axios';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loginView',
    component: loginView
  },
//   {
//     path: '/main',
//     name: 'main',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "main" */ '../views/main.vue')
//   },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/register',
    name: 'registerView',
    component: registerView
  },
  {
    path: '/passwordRecover',
    name: 'PasswordRecoverView',
    component: PasswordRecoverView
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from,next) => {
  if (
    // 检查用户是否已登录
    !localStorage.getItem('token') &&
    // ❗️ 避免无限重定向
    to.name !== 'loginView' && to.name !== 'registerView' && to.name !=='PasswordRecoverView'
  ) {
    // 将用户重定向到登录页面
    next('/')
  }else{
    // 获取Token值，可以从localStorage或其他地方获取
    const token = localStorage.getItem('token');

      // 将Token作为参数传递给后端Node.js
      axios.defaults.params = { token };

    
    
    next()
  }

  if(
    localStorage.getItem('token') &&
  // ❗️ 避免无限重定向
  to.name == 'loginView' ){
    // 将用户重定向
    next('/home')
  }
  


})

export default router
