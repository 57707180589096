<template>
  <div>
    <el-row>
      <el-col :span="24" :align="isNotSmallWindow ? 'middle' : ''"   :justify="isNotSmallWindow ? 'center' : ''" >
        <el-container>
          <el-main>
            <el-card :class="{'box-card': isNotSmallWindow}">
              <h2>注册</h2>
              <el-form label-width="15%" :model="user" ref="user" :rules="rules" :label-position="isNotSmallWindow ? '' : 'top'">
                <el-form-item
                  label="账号"
                  :rules="[
                    { required: true, message: '账号不能为空'},
                  ]"
                  prop="uname"
                >
                  <el-input v-model="user.uname" maxlength="8" minlength="3" show-word-limit clearable autocomplete="off" @blur="checkUname"></el-input>
                </el-form-item>
                <el-form-item show-message
                  label="密码"
                  :rules="[
                    { required: true, message: '密码不能为空'},
                  ]"
                  prop="upwd"
                >
                <el-input type="password" v-model="user.upwd" autocomplete="off" maxlength="15" min="6" clearable show-password @blur="checkUpwd"></el-input>
                </el-form-item>

                <el-form-item show-message
                  label="邮箱"
                  :rules="[
                    { required: true, message: '邮箱不能为空'},
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                  ]"
                  prop="email"
                >
                <el-input type="email" v-model="user.email" autocomplete="off" clearable @blur="checkEmail"></el-input>
                </el-form-item>

                <el-form-item 
                  v-if="showVcode"
                  label="验证码"
                  :rules="[
                    { required: true, message: '验证码不能为空'},
                  ]"
                  prop="vcode"
                >
                <el-input type="text" v-model="user.vcode" autocomplete="off" clearable ></el-input>
                </el-form-item>

                <el-form-item :style="isNotSmallWindow ? 'margin-left: 75%' : ''">
                <el-button @click="sendVerificationCode" :disabled="disableSendButton">{{ sendButtonText }}</el-button>
                </el-form-item>

                <el-form-item>
                  <el-button :class="{'sbtn':!isNotSmallWindow}" style="width: 55%;margin-left: -20%;" type="primary" @click="submit()" :loading="registerLoading">注册</el-button>
                </el-form-item>
                <el-link type="primary" @click="toLogin()" >已经有账号，点击登录</el-link>
              </el-form>
            </el-card>
          </el-main>
        </el-container>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      user:{
        uid:'',
        uname: '',
        upwd: '',
        email:'',
        vcode:''
      },
      disableSendButton: false,
      sendButtonText: '获取验证码',
      countdown: 60,
      showVcode:false,
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入有效的邮箱地址', trigger: ['blur', 'change'] }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      registerLoading: false,
      vcodeState:false,
      emailState:false,
      unameState:false,
      upwdState:false,
      windowWidth: window.innerWidth
    }
  },
  methods:{
  submit(){
    this.$refs.user.validate(valid => {
        if (valid) {
         // 表单验证通过
         if (!this.user.vcode) {
            this.$message.error('请输入验证码');
            return;
          }else if(this.unameState==false){
            this.$message.error('用户名长度不能小于3位')
            return;
          }else if(this.upwdState==false){
            this.$message.error('密码长度不能小于6位')
            return;
          }

          // 模拟验证码验证
          // 实际情况需要将验证码发送到后端进行验证
          // 这里只是一个简单的示例
          this.$http.post('/users/checkCode', {code:this.user.vcode, email: this.user.email})
          .then(response => {
            if(response.data[0].count=='0'){
              this.$message.error('验证码错误，请重新输入！');
              this.vcodeState=false
              console.log(`查完后的vcodeState=${this.vcodeState}`)
            }else{
              this.vcodeState=true
              console.log(`这里是验证码对了的vcodeState=${this.vcodeState}`)
            }
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });

          console.log(`注册前的vcodeState=${this.vcodeState}`)
          // 注册请求的示例代码
          // 实际情况需要将注册请求发送到后端进行处理
          // 这里只是一个简单的示例
          this.registerLoading == true
          // this.$uuid() UUID
          if(this.vcodeState&&this.unameState&&!this.emailState&&this.upwdState){
            this.$http.post('/users/register', {uid:this.$uuid(), uname: this.user.uname,upwd:this.user.upwd,email:this.user.email})
          .then(response => {
            if(response.data=='1'){
              this.$message({
                message: '注册成功，跳转登录！',
                type: 'success'
              });
              this.user={}
              this.$router.push("/")
            }else{
              this.$message.error('注册失败，请重试！')
              this.$refs.user.resetFields()
            }
            
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
          }
          
        } else {
          // 表单验证未通过
          this.$message.error('请将信息填写完整！');
          return false;
        }
      });
    // console.log(userData.uname)
    // console.log(userData.upwd)
  },
  toLogin(){
    this.$router.push('/')
  },
  checkUname(){
    if (!this.user.uname) {
            this.unameState=false
            return;
          }else if(this.user.uname.length<3){
      this.unameState=false
      this.$message.error('用户名长度不能小于3位')
      return
    }
    // 检查用户名是否注册
    this.$http.post('/users/checkUname', {uname:this.user.uname})
          .then(response => {
            if(response.data[0].count!=0){
              this.$message.error('用户名不可用，请重新输入！');
              this.unameState=false
              return;
            }else{
              this.unameState=true
            }
                
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
  },
  checkEmail(){
    if (!this.user.email) {
            // this.$message.error('请输入验证码');
            return;
          }
      // 检查邮箱是否可用
      this.$http.post('/users/checkEmail', {email:this.user.email})
          .then(response => {
            if(response.data[0].count!=0){
              this.$message.error('邮箱地址不可用，请重新输入！');
              this.emailState=true
              return;
            }else{
              this.emailState=false

            }
                
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });

  },
  checkUpwd(){
    if (!this.user.upwd) {
            this.upwdState=false
            return;
          }else if(this.user.upwd.length<6){
            this.$message.error('密码长度不能小于6位')
            this.upwdState=false
          }else{
            this.upwdState=true
          }
  },
  sendVerificationCode() {
    if (!this.user.email) {
        this.$message.error('请输入邮箱');
        return;
      }
    if(this.emailState===true){
      this.$message.error('邮箱地址不可用，请更换一个！');
      return;
    }
    this.showVcode=true
      // 发送验证码的逻辑
      // 在此处执行发送验证码的异步操作，例如向后端API发送请求
      this.$http.post('/users/send-verification-code', {email: this.user.email})
    .then(response => {
      if(response.data.message=='Verification code sent successfully.'){
        this.$message({
          message: '验证码发送成功，请登录邮箱查收！',
          type: 'success'
        });
      }else{
        this.$message.error('验证码发送失败，请重试！')
      }
      
      
    })
    .catch(error => {
      // this.$message.error('网络好像出错了！别着急等会再试试')
      console.error(error);
      if(error.response.data.error=='Too many requests. Please try again after 60 seconds.'){
        this.$message.error('验证码发送频繁，请60秒后重试！')
      }
      
    });

      // 模拟发送验证码的异步操作
      this.disableSendButton = true;
      this.sendButtonText = `发送中 (${this.countdown}s)`;

      const timer = setInterval(() => {
        this.countdown -= 1;
        this.sendButtonText = `等待 (${this.countdown}s)后`;

        if (this.countdown === 0) {
          clearInterval(timer);
          this.disableSendButton = false;
          this.sendButtonText = '获取验证码';
          this.countdown = 60;
        }
      }, 1000);
      
    },
  Resize() {
      this.windowWidth = window.innerWidth;
  },
  },
computed: {
    isNotSmallWindow() {
      return this.windowWidth > 768; // 设置阈值为768px，可以根据需要调整
    }
  },
mounted() {
    window.addEventListener('resize', this.Resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.Resize);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box-card{
  width: 40%;
}
.sbtn{
  margin-left: 0 !important;
}
</style>
