import Vue from 'vue'
import Vuex from 'vuex'
// 引入CryptoJS
import CryptoJS from 'crypto-js';

Vue.use(Vuex)
 

// 新建并暴露store
export default new Vuex.Store({
    // 用来存储数据
    state(){
        return{
            uname:'',
            unametoken:'',
            previewId:'',
            woecmd:'',
            editId:''

        }
    },
    // 响应组件中的事件
    actions:{
        // 生成token值方法
        async fetchData({ state,commit }) {
                const unametoken = CryptoJS.AES.encrypt(state.uname,'20011017').toString();
                commit('setData', unametoken);
            },

    },
    // 操作数据
    mutations:{
        // 将token保存到本地
        setData(state,unametoken) {
            state.unametoken=unametoken
            // 将数据存储到本地
            localStorage.setItem('token', JSON.stringify(state.unametoken));
        },
        // 清除token值
        clearData(state) {
            state.unametoken = null;
            // 清除本地存储的数据
            localStorage.removeItem('token');
        },
        // 设置登录用户名
        setUname(state,uname){
            state.uname=uname
            console.log('当前用户名是：'+state.uname)
        },
        // 设置查看的ID号
        setpreviewId(state,previewId){
            state.previewId=previewId
            console.log('当前查看的ID是：'+state.previewId)
        },
        // 设置woecmd
        setWoecmd(state,woecmd){
            state.woecmd=woecmd
            // console.log('当前的woecmd是：'+state.woecmd)
        },
        // 设置编辑的ID号
        setEditId(state,editId){
            state.editId=editId
        }
    },
    // 用来将state数据进行加工
    getters:{
        getUname:(state)=>state.uname
    }
})
 