<template>
    <div>
    <el-row>
    <el-col :span="24" align="middle" justify="center">
    <el-container>
        <el-main>
      <el-card shadow="never" style="border:none">
        <i class="el-icon-user-solid"></i>
        <span class="comTitle">密码找回</span>
        <hr>
        <!-- 步骤条组件 -->
        <el-steps :active="activeStep" finish-status="success" align-center>
          <el-step title="验证邮箱" description="填写邮箱并获取验证码"></el-step>
          <el-step title="重置密码" description="设置新密码"></el-step>
          <el-step title="完成" description="密码重置成功"></el-step>
        </el-steps>
        <!-- 步骤内容 -->
        <el-form v-if="activeStep === 0" :key="'emailForm'" ref="emailForm" :model="emailForm" :rules="emailRules" label-width="120px">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="emailForm.email" @blur="checkEmail"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="emailForm.code"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="sendCode" :disabled="emailForm.email === ''">发送验证码</el-button>
            <el-button type="primary" @click="nextStep(1)" :disabled="toFlag">下一步</el-button>
          </el-form-item>
        </el-form>

        <el-form v-if="activeStep === 1" :key="'resetForm'" ref="resetForm" :model="resetForm" :rules="resetRules" label-width="120px">
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="resetForm.newPassword"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input type="password" v-model="resetForm.confirmPassword" ></el-input>
          </el-form-item>
          <el-form-item>
            <!-- <el-button @click="prevStep">上一步</el-button> -->
            <el-button type="primary" @click="resetPassword" :disabled="toFlag">重置密码</el-button>
          </el-form-item>
        </el-form>

        <div v-if="activeStep === 2">
          <p>密码重置成功！</p>
          <el-button type="primary" @click="goLogin()">跳转登录</el-button>
        </div>
      </el-card>
    </el-main>
        </el-container>
      </el-col>
    </el-row>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeStep: 0,
        emailForm: {
          email: '',
          code: ''
        },
        resetForm: {
          newPassword: '',
          confirmPassword: ''
        },
        emailRules: {
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { type: 'email', message: '邮箱格式不正确', trigger: 'blur' },
            {validator:(rule,value,callback)=>{
                if(this.emailForm.code&&value.length>0){
                    this.toFlag=false
                    callback()
                }
            }}
          ],
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            {validator:(rule,value,callback)=>{
                if(this.emailForm.email&&value.length>0){
                    this.toFlag=false
                    callback()
                }
            }}
          ]
        },
        resetRules: {
          newPassword: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { validator: (rule, value, callback) => {
                if (value.length < 6) {
                callback(new Error('密码长度不能少于6位'));
                } else {
                // 在此添加您的自定义验证规则
                // 例如，检查密码是否包含特殊字符等
                callback();
                }
            },
            trigger: 'blur' 
            }
          ],
          confirmPassword: [
            { required: true, message: '请确认密码', trigger: 'blur' },
            { validator: (rule, value, callback) => {
                if (value!== this.resetForm.newPassword) {
                    callback(new Error('两次输入的密码不一致'));
                } else {
                    callback();
                    this.toFlag=false
                }
            },
            trigger: 'blur' 
            }
          ]
        },
        toFlag:true,
        token:'',
        emailState:''
      };
    },
    methods: {
      sendCode() {
        if (!this.emailForm.email) {
        this.$message.error('请输入邮箱');
        return;
      }
      if(this.emailState===true){
      this.$message.error('请确认邮箱是否注册！');
      return;
    }
       // 发送验证码的逻辑
      // 在此处执行发送验证码的异步操作，例如向后端API发送请求
      this.$http.post('/users/send-verification-code', {email: this.emailForm.email})
    .then(response => {
      if(response.data.message=='Verification code sent successfully.'){
        this.$message({
          message: '验证码发送成功，请登录邮箱查收！',
          type: 'success'
        });
        
      }else{
        this.$message.error('验证码发送失败，请重试！')
      }
      
      
    })
    .catch(error => {
      // this.$message.error('网络好像出错了！别着急等会再试试')
      console.error(error);
      if(error.response.data.error=='Too many requests. Please try again after 60 seconds.'){
        this.$message.error('验证码发送频繁，请60秒后重试！')
      }
      
    });

      // 模拟发送验证码的异步操作
      this.disableSendButton = true;
      this.sendButtonText = `发送中 (${this.countdown}s)`;

      const timer = setInterval(() => {
        this.countdown -= 1;
        this.sendButtonText = `等待 (${this.countdown}s)后`;

        if (this.countdown === 0) {
          clearInterval(timer);
          this.disableSendButton = false;
          this.sendButtonText = '获取验证码';
          this.countdown = 60;
        }
      }, 1000);
  
      },
      nextStep() {
        // console.log(this.activeStep+"------sasasas")
        if (this.activeStep === 0) {
            if(!this.emailForm.email){
                this.message.error('邮箱不能为空！')
                return
            }
            if(!this.emailForm.code){
                this.message.error('验证码不能为空！')
                return
            }
        if(this.emailForm.email&&this.emailForm.code){
            this.$http.post('/users/prcheckCode', {code:this.emailForm.code, email: this.emailForm.email})
          .then(response => {
            console.log(response)
            if(response.data=='104'){
              this.$message.error('验证码错误，请重新输入！');
              
            //   this.vcodeState=false
            //   console.log(`查完后的vcodeState=${this.vcodeState}`)
            }else{
            this.token=JSON.stringify(response.data)
            // console.log(this.token)
            this.toFlag=true
            // 验证通过，进入下一步
            this.activeStep = 1 ;
            //   this.vcodeState=true
            //   console.log(`这里是验证码对了的vcodeState=${this.vcodeState}`)
            }
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
        }                      
          
        } 
      },
      resetPassword() {
            if(!this.resetForm.newPassword){
                // this.message.error('新密码不能为空！')
                return
            }
            if(!this.resetForm.confirmPassword){
                // this.message.error('确认密码不能为空！')
                return
            }
            if(this.resetForm.confirmPassword!=this.resetForm.newPassword){
                this.message.error('两次输入的密码不相同，请确认！')
                return
            }

            console.log('表单通过了！')
                
            this.$http.post(`/users/prchangePwd?token=${this.token}`, {upwd:this.resetForm.newPassword,email:this.emailForm.email})
          .then(response => {
            if(response.data=='103'){
                

                // 验证通过，进入下一步
                this.activeStep = this.activeStep + 1;


            }else{
              this.$message.error('密码修改失败，请重试！')
              
            }
            
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
  
      
        
        // 模拟密码重置成功
        // this.activeStep = 2;
      },
      reset() {
        // 重新找回密码，重置表单和步骤
        this.activeStep = 0;
        this.emailForm.email = '';
        this.emailForm.code = '';
        this.resetForm.newPassword = '';
        this.resetForm.confirmPassword = '';
      },
      checkEmail(){
            if (!this.emailForm.email) {
                    // this.$message.error('请输入验证码');
                    return;
                }
            // 检查邮箱是否可用
            this.$http.post('/users/checkEmail', {email:this.emailForm.email})
                .then(response => {
                    if(response.data[0].count==0){
                    this.$message.error('请确认邮箱是否注册！');
                    this.emailState=true
                    return;
                    }else{
                    this.emailState=false

                    }
                        
                })
                .catch(error => {
                    this.$message.error('网络好像出错了！别着急等会再试试')
                    console.error(error);
                });
     },
     goLogin(){
        this.$router.push('/')
     }
    },
  };
  </script>
  
  <style scoped>
  .comTitle {
    font-size: 20px;
    margin-left: 10px;
  }
  </style>