<template>
  <div>
        <el-row>
        <el-col :span="4" justify="center" align="middle">
        <el-menu
        default-active="1"
        class="leftMenu"
        style="height:770px;"
        mode="vertical"
        :collapse="!isNotSmallWindow"
        >
        <h3>主页</h3>
        <el-submenu index="2">
           
            <template slot="title">
            <i class="el-icon-user-solid"></i>
            <span>个人中心</span>
            </template>
            <el-menu-item-group>
            <el-menu-item index="1-1" @click="showFlag=0">个人信息</el-menu-item>
            <el-menu-item index="1-2" @click="showFlag=1">密码修改</el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="2"  @click="writeFun">
            <i class="el-icon-edit"></i>
            <span slot="title">写日记</span>
        </el-menu-item>
        <!-- <el-menu-item index="3"  @click="showFlag=3">
            <i class="el-icon-view"></i>
            <span slot="title">我的日记-查看</span>
        </el-menu-item> -->
        <el-menu-item index="4" @click="showFlag=4">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">我的日记-管理</span>
        </el-menu-item>
        <el-menu-item index="5" @click="logoff()">
            <i class="el-icon-circle-close"></i>
            <span slot="title">退出登录</span>
        </el-menu-item>
        </el-menu>
            </el-col>
            <el-col :span="18" justify="center">
                <div v-if="showFlag==0">
                    <personalInformation></personalInformation>
                </div>
                <div v-if="showFlag==1">
                    <upwdChange></upwdChange>
                </div>
                <div v-if="showFlag==2">
                    <writeDiary @showFlag="getshowFlag"></writeDiary>
                </div>
                <div v-if="showFlag==3">
                    <previewDiary @showFlag="getshowFlag"></previewDiary>
                </div>
                <div v-if="showFlag==4">
                    <manageDiary @showFlag="getshowFlag"></manageDiary>
                </div>
            </el-col>
        </el-row>
  </div>
</template>

<script>

import writeDiary from "../components/writeDiary.vue"
import manageDiary from "../components/manageDiary.vue"
import personalInformation from "../components/pInfo.vue"
import previewDiary from "../components/previewDiary.vue"
import upwdChange from "@/components/upwdChange.vue"
export default {
    components:{
        writeDiary,
        manageDiary,
        personalInformation,
        previewDiary,
        upwdChange
    },
    data() {
    return {
      showFlag:0,
      windowWidth: window.innerWidth,
      messageboxClassName:'',
    }
  },
  methods:{
    logoff(){
       // 设置删除弹窗的样式 函数
       this.getMessageBoxClassName()

        this.$confirm('确定要退出登录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: this.messageboxClassName
        }).then(() => {
          this.$message({
            type: 'success',
            message: '退出登录成功!'
          });
          this.$store.commit('clearData')
          // this.$router.push('/')
          location.reload()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出登录'
          });          
        });
        
    },
    // 获取子组件传参 父组件设置
    getshowFlag(showFlag){
        this.showFlag = showFlag
    },
    writeFun(){
        this.showFlag=2     
    },
    Resize() {
      this.windowWidth = window.innerWidth;
    },
    // 设置弹窗样式的函数 
    getMessageBoxClassName(){
      if(!this.isNotSmallWindow){
        this.messageboxClassName='my-message-box'
      }else{
        this.messageboxClassName=''
      }    
    }
  },
  created(){
    // 从vueX中获取登录的用户名
        let token=localStorage.getItem('token')
        let bytes=this.$crypto.AES.decrypt(JSON.parse(token), '20011017');
        let decryptedToken = bytes.toString(this.$crypto.enc.Utf8)
        this.$store.commit('setUname',decryptedToken)
        if(token&&decryptedToken==''){
            this.$message({
                showClose: true,
                message: '登录失效，请重新登录！',
                type: 'error'
            });
            this.$store.commit('clearData')
            location.reload()        
        }
        
    
  },
  computed: {
    isNotSmallWindow() {
      return this.windowWidth > 768; // 设置阈值为768px，可以根据需要调整
    }

  },
mounted() {
    window.addEventListener('resize', this.Resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.Resize);
  },
}
</script>

<style scoped>
/* 设置弹窗大小和位置 */
.my-message-box{
  width: 100%;
  margin-top: 60%;
}
</style>