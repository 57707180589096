<template>
  <div>
    <el-card shadow="never" style="border:none">
        <i class="el-icon-view"></i>
        <span class="comTitle">我的日记-查看</span>
        <el-button type="primary" @click="generatePDF" size="small" style="float: right;">导出为图片</el-button>
        <hr>
        <!-- <el-page-header @back="goBack()">
        </el-page-header> -->

        <el-row id="image-content" v-loading="loading">
            <el-col :span="24" justify="center" align="middle">
                <h3>{{ diaryData.dtitle }}</h3>
            </el-col>
            <el-col :span="24" justify="center" align="left">
                <p class="timeTxt">时间：{{diaryData.dtime}}</p>
            </el-col>
            <el-col :span="24" justify="center" >
                <v-md-preview :text="diaryData.dcontent"></v-md-preview>
            </el-col>
        </el-row>
        
    </el-card>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
    data(){
        return{
            did:'',
            diaryData:{
            dtitle:'',
            dcontent:``,
            dtime:""
            },
            loading:true,         
        }
    },
    mounted(){
        // 数据初始化
        if(this.$store.state.previewId!=null){
            this.did=this.$store.state.previewId
            this.$http.post('/diary/getPOneDiary', { did: this.did})
            .then(response => {
                // console.log(response)
                this.diaryData=response.data[0]
                // console.log(this.diaryData)
                this.loading=false
                
            })
            .catch(error => {
                this.$message.error('网络好像出错了！别着急等会再试试')
                console.error(error);
            });
        }  
    },
    methods:{
        goBack(){
            this.$emit('showFlag', 4)
        },
        generatePDF() {
            // 获取要转换为图片的HTML元素
            const element = document.getElementById('image-content');

            // 使用html2canvas将HTML元素转换为Canvas
            html2canvas(element).then((canvas) => {
            // 将Canvas转换为图片
            const imageData = canvas.toDataURL('image/png');

            // 创建一个下载链接
            const link = document.createElement('a');
            link.href = imageData;
            link.download = `${this.diaryData.dtitle}.png`;

            // 触发点击下载链接
            link.click();
      });
    }
  },
}
</script>

<style>
.timeTxt{
    font-size: 12px;
    margin-left: 5%;
    color: #909399;
}
</style>