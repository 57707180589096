<template>
    <div>
      <el-card shadow="never" style="border:none">
        <i class="el-icon-user-solid"></i>
        <span class="comTitle">修改密码</span>
        <hr>
        <el-form label-width="15%" ref="form" :model="form" :rules="rules" @submit.native.prevent="submitForm" :label-position="isNotSmallWindow ? '' : 'top'">
            <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model="form.newPassword" maxlength="15"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
                <el-input type="password" v-model="form.confirmPassword" maxlength="15"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" native-type="submit">提交</el-button>
            </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          newPassword: '',
          confirmPassword: ''
        },
        rules: {
          newPassword: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { validator: this.validateNewPassword, trigger: 'blur' }
          ],
          confirmPassword: [
            { required: true, message: '请确认密码', trigger: 'blur' },
            { validator: this.validateConfirmPassword, trigger: 'blur' }
          ]
        },
        windowWidth: window.innerWidth
      };
    },
    methods: {
      validateNewPassword(rule, value, callback) {
        if (value && value.length < 6) {
          callback(new Error('密码长度不能少于6位'));
        } else {
          callback();
        }
      },
      validateConfirmPassword(rule, value, callback) {
        if (value !== this.form.newPassword) {
          callback(new Error('两次输入的密码不一致'));
        } else {
          callback();
        }
      },
      submitForm() {
        this.$refs.form.validate(valid => {
          if (valid) {
            // 表单验证通过，可以执行提交逻辑
            // 检验当前密码是否正确
            this.$http.post(`/users/changePwd`, {upwd:this.form.newPassword})
          .then(response => {
            if(response.data=='103'){
                // 清空表单字段
                this.form.currentPassword = '';
                this.form.newPassword = '';
                this.form.confirmPassword = '';

                this.$message.success('密码修改成功');

            }else{
              this.$message.error('密码修改成功，请重试！')
              this.$refs.user.resetFields()
            }
            
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
  
            
          }
        });
      },
      Resize() {
      this.windowWidth = window.innerWidth;
  },
    },
computed: {
    isNotSmallWindow() {
      return this.windowWidth > 768; // 设置阈值为768px，可以根据需要调整
    }
  },
mounted() {
    window.addEventListener('resize', this.Resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.Resize);
  },
  };
  </script>
  
  <style scoped>
  .comTitle {
    font-size: 20px;
    margin-left: 10px;
  }
  </style>