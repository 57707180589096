<template>
  <div>
    <el-card shadow="never" style="border:none" >
        <i class="el-icon-edit-outline"></i>
        <span class="comTitle">日记管理</span>
        <hr>
        <el-table
            sort-by="dtime"
            :default-sort = "{prop: 'dtime', order: 'descending'}"
            v-loading="loading"
            :data="diaryData.filter(data => !search || data.dtitle.toLowerCase().includes(search.toLowerCase()))"
            style="width: 100%">
            <!-- 不显示ID -->
            <el-table-column v-if="false"
            label="ID"
            prop="did">
            </el-table-column>

            <el-table-column
            sortable
            label="日期"
            prop="dtime">
            </el-table-column>

            <el-table-column
            sortable
            label="标题"
            prop="dtitle">
            </el-table-column>
            
            <el-table-column
            align="right">
            <template slot="header" slot-scope="scope">
                <el-input
                v-model="search"
                size="mini"
                placeholder="输入标题搜索"/>
            </template>

            <template slot-scope="scope">
                <el-button
                size="mini"
                type="success"
                @click="Preview(scope.$index, scope.row)">查看</el-button>
                <el-button
                size="mini"
                type="primary"
                @click="Edit(scope.$index, scope.row)">编辑</el-button>
                <el-button
                size="mini"
                type="danger"
                @click="Delete(scope.$index, scope.row)">删除</el-button>
            </template>
            </el-table-column>           
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            @prev-click="getPrevData"
            @next-click="getNextData"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            :total="totalItems">
        </el-pagination>
    </el-card>
  </div>
</template>

<script>

export default {
    data() {
    return {
      pdId:'',
    diaryData:[],
    search:'',
    currentPage: 1, // 当前页码
    pageSize: 10, // 每页显示的条数
    totalItems: 0, // 总条数
    startNum:0,
    // 偏移量 查询多少行
    offsetNum:10,
    loading:true,
    messageboxClassName:'',
    windowWidth: window.innerWidth

    }
  },
  methods: {
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // 切换每页显示条数时，重置当前页码为 1
      console.log('pageSize 改变时会触发')
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.startNum=(newPage-1)*10
      this.getAllDiarylimit()
    },
    // 查看
    Preview(index, row) {
      // 存储需要预览的ID，用于后台查询
        this.$store.commit("setpreviewId",row['did'])
        // 向父组件传输数据
        this.$emit('showFlag', 3)
    },
    // 编辑
    Edit(index, row) {
        // 存储需要编辑的ID，用于后台查询
        this.$store.commit("setEditId",row['did'])
        //设置setWoecmd为edit
        this.$store.commit("setWoecmd",'edit')
        // 向父组件传输数据
        this.$emit('showFlag', 2)
    },
    // 删除
    Delete(index, row) {
      // 设置删除弹窗的样式 函数
      this.getMessageBoxClassName()

      this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: this.messageboxClassName

        }).then(() => {
          // 删除数据操作
          this.diaryData.splice(index,1)
          // 请求后端删除操作
          this.$http.post('/diary/del', { did: row['did']})
          .then(response => {
            if(response.data=="101"){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getAllDiarylength()
              this.getAllDiarylimit()
            }
            
            
          })
          .catch(error => {
            this.$message.error('网络好像出错了！别着急等会再试试')
            console.error(error);
          });
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      
      // console.log(index, row);
      
    },
    getAllDiarylength(){
      // 获取所有日记长度
      this.$http.post('/diary/getAllDiarylength', { uname: this.$store.getters.getUname})
      .then(response => {
        // 在实际应用中，您需要从后端获取数据，并更新 tableData 和 totalItems
        // 赋值数据总数
        this.totalItems =response.data['count(*)']
        // console.log(response.data['count(*)'])
        
      })
      .catch(error => {
        this.$message.error('网络好像出错了！别着急等会再试试')
        console.error(error);
      });

    },
    getAllDiarylimit(){
      this.loading=true

      this.$http.post('/diary/getAllDiarylimit', { uname: this.$store.getters.getUname,startNum:this.startNum,endNum:this.offsetNum})
      .then(response => {
        // 在实际应用中，您需要从后端获取数据，并更新 tableData 和 totalItems
        // 赋值数据总数
        this.diaryData=response.data
        this.loading=false
        
      })
      .catch(error => {
        // this.$message.error('网络好像出错了！别着急等会再试试')
        console.error(error);
        this.loading=false
      });

    },
    getPrevData(){
      if(this.startNum-this.pageSize>=0){
        this.startNum-=this.pageSize
      }
      console.log("当前开始行数："+this.startNum)
      this.getAllDiarylimit()   
      
    },
    getNextData(){
      if(this.startNum+this.pageSize< this.totalItems){
        this.startNum+=this.pageSize
      }
      console.log("当前开始行数："+this.startNum)
      this.getAllDiarylimit()
    },
    Resize() {
      this.windowWidth = window.innerWidth;
  },
    getMessageBoxClassName(){
      if(!this.isNotSmallWindow){
        this.messageboxClassName='my-message-box'
      }else{
        this.messageboxClassName=''
      }    
    }
  },
computed: {
    isNotSmallWindow() {
      return this.windowWidth > 768; // 设置阈值为768px，可以根据需要调整
    }
  },
  mounted(){
    window.addEventListener('resize', this.Resize);

    // 加载组件时 放后端获取数据
    this.getAllDiarylength()
    this.getAllDiarylimit()
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.Resize);
  }
}

</script>

<style>
.comTitle{
    font-size: 18px;
    font-weight: bold;
}
/* 设置弹窗大小和位置 */
.my-message-box{
  width: 100%;
  margin-top: 60%;
}
</style>