<template>
  <div>
    <el-card shadow="never" style="border:none">
        <i class="el-icon-user-solid"></i>
        <span class="comTitle">个人信息</span>
        <hr>
        <p>你好哇！！！！！</p>
        <span>用户名：</span><el-tag>{{ uname }}</el-tag>

    </el-card>
  </div>
</template>

<script>
export default {
    data() {
        return{
            uname:'大帅哥',
        }
    },
    created(){
        // 从vueX中获取登录的用户名
        let token=localStorage.getItem('token')
        let bytes=this.$crypto.AES.decrypt(JSON.parse(token), '20011017');
        let decryptedToken = bytes.toString(this.$crypto.enc.Utf8)
        this.uname=decryptedToken
    }
}
</script>

<style>

</style>